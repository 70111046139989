.purchase-content{
    background:#fff;
    min-height: calc(100vh - var(--header-height));
}

.cart-section{
    height: calc(100vh - var(--header-height , 0 ) - 7.5rem);
}

.cart-section.banner-text{
    height: calc(100vh - var(--header-height , 0 ) - 10.5rem);
}

.purchase-modal-footer{
    border-top : 1px solid #eeedf2;
    /* padding: 24px; */
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-checkout-button{
    position: fixed;
    bottom: 0px;
    width: 100%;
    background : var(--main-color);
}

.footer-checkout-button.disabled{
    position: fixed;
    bottom: 0px;
    width: 100%;
    background : var(--secondary-button-color);
    color : var(--secondary-text-color);
}