.ticket2 {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 700px; */
  /* margin: 100px; */

  /* filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.1)); */
}
.ticket2__title {
  position: relative;
  background-color: #ffc727;
  color : #fff;
  padding: 1rem;
  /* padding-bottom: 0; */
  border-radius: 20px 20px 0 0;
}


.ticket-card{
  border-radius: 20px;
  padding: 1rem;
  /* color : #fff; */
  border: 1.5px solid #cbd5e0;
}

/* .ticket2__title::after {
  position: absolute;
  content: "";
  height: 1px;
  border-top: 2px dashed #eee;
  left: 20px;
  right: 20px;
  bottom: -20px;
  z-index: 2;
} */

.ticket2__form {
  position: relative;
  background-color: #fff;
}

.ticket2__header {
  position: relative;
  background-color: #fff;
  padding: 1rem;
  /* padding-bottom: 0; */
  font-size: 1.2rem;
  font-weight: 600;
  /* border-radius: 5px 5px 0 0; */
  border: 2px solid #cbd5e0;
  border-bottom: none;
  min-height: 120px;
}
.ticket2__header.top-left-right-border{
  border-radius: 20px 20px 0 0;
}
.ticket2__header::after {
  position: absolute;
  content: "";
  height: 1px;
  border-top: 2px dashed #cbd5e0;
  left: 20px;
  right: 20px;
  bottom: -20px;
  z-index: 1;
}
.ticket2__rip {
  position: relative;
  height: 40px;
  flex-shrink: 0;
  margin: 0 20px;
  background-color: #fff;
}
.ticket2__rip::before, .ticket2__rip::after {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 50%;
  top: 50%;
  border: 2px solid #cbd5e0;
}
.ticket2__rip::before {
  left: -20px;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
}
.ticket2__rip::after {
  right: -64px;
  /* transform: translate(-50%, -50%) rotate(225deg); */
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
}
.ticket2__body {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0 0 20px 20px;
  padding-top: 0;
  border : 2px solid #cbd5e0;
  border-top: none;
}