.park-header-intro{
    background:
    linear-gradient(rgba(226,248,255, 0.5) , #fff),
    linear-gradient(to right, #d6e4ff, #f9f0ff);
    /* linear-gradient(to right, #e2f8ff, #dffafd, #defdfa, #e0fef4, #e4ffee),
    linear-gradient(to bottom, #fff); */
    background-blend-mode: screen;
}

.event-hero{
    border-radius: 12px;
    position: relative;
    /* max-width: 1080px;
    max-width: var(--container-max-width); */
    width: '100%';
    margin: 0 auto;
    text-align: center;
    max-height: 50vw;
    overflow: hidden;
    background-color: #fff;
}

.background-image-blur{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    /* max-width: var(--container-max-width); */
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-filter: blur(50px) brightness(.9);
    filter: blur(50px) brightness(.9);
    z-index: 0;
}


.purchase-modal .ant-modal-content{
    padding:0
}

.step-no{
    background:#F8F7FA;
    height:40px;
    width:40px;
    display:flex;
    align-items : center;
    justify-content : center;
    border-radius: 6px;
}

.step-no span{
    font-weight: bold;
    font-size: 20px;
    color: var(--main-color);
}

.step-label span{
    font-size: 20px;
    font-weight: bold;
    color: rgb(30, 10, 60);
}

.purchase-visit-date > .ant-picker-input{
    border-bottom: 2px solid #cbd5e0;
}

.purchase-visit-date > .ant-picker-input > input::placeholder {
    color: #cbd5e0;
  }

.purchase-visit-date > .ant-picker-input > input,
.purchase-visit-date{
    font-size: 24px;
    font-weight: bold;
    color: var(--main-color);
}

.ticket-type-card{
    border-color: #cbd5e0;
    /* cursor: pointer; */
    transition: box-shadow .24s cubic-bezier(.4,0,.3,1),background-color .24s cubic-bezier(.4,0,.3,1),-webkit-box-shadow .24s cubic-bezier(.4,0,.3,1);
}

.ticket-type-card.selected {
    border: 1px solid var(--main-color);
    background : var(--lighten-main-color);
    box-shadow: 0 16px 64px -16px rgba(46,55,77,.24) !important;
}

.ticket-type-card.selected span.header-font {
    color: var(--main-color) !important;
}

.ticket-type-card:hover{
    box-shadow: 0 16px 64px -16px rgba(46,55,77,.24) !important;
}

.purchase-modal-footer{
    /* box-shadow: 0 -18px 50px 0 rgba(var(--eds-background-rgb,255,255,255),.8); */
    border-top : 1px solid #eeedf2;
    /* padding: 24px; */
}

.card-overlay {
    background: rgba(0, 0, 0, 0.4);
}


.ticket-title.h1{
    font-size: 1.75rem;
    /* image */
    background-clip: text;
	/* background-image: url('https://s3.amazonaws.com/usefulangle/news/95-5f6cc1ad5575e.jpg') !important; */
}

.purchase-step.bullet{
    background : #f5f5f5;
    height:8px;
    width: 100%;
}

.purchase-step.bullet.selected{
    background : #d9d9d9;
    height:8px;
    width: 100%;
}

.purchase-step.bullet.left-bordered{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.purchase-step.bullet.right-bordered{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

/* float button */
.mobile-cart-float-button.ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon{
    width: auto;
}

.select-visit-date-first{
    opacity: 0;
    pointer-events: none;
}

.select-visit-date-first.selected{
    opacity: 1;
    pointer-events: all;
}

.purchase-summary-drawer .ant-drawer-close{
    position: absolute;
    top: 20px;
    right: 0;
}

/* hide year antd picker */
.purchase-visit-date-dropdown .ant-picker-header > button.ant-picker-header-super-prev-btn,
.purchase-visit-date-dropdown button.ant-picker-header-super-next-btn {
      display: none !important;
}

/* quill */
.description-quill .ql-editor p{
    margin: 0 !important;
}

@media (max-width : 768px) {
    .purchase-modal-footer{
        padding : 12px 24px;
        max-height: 150px;
        /* background: #f8f7fa; */
    }
}

@media (min-width: 960px){
    .ticket-title.h1{
        font-size: 2.45rem;
    }
}