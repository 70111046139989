.grey-card:hover .right-arrow{
    color: #000;
    transform: translateX(30%);
}

.grey-card .right-arrow{
    color: #8c8c8c;
    /* transform: translateX(-100%); */
    transition: all 0.5s;
}

.grey-card:hover .features-image{
    transform: scale(1.1);
    /* transform:  translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-property: transform,-webkit-transform;
    transition-duration : .2s; */
    transition: all ease-in-out .2s;
}

.brick-background{
    background-image: url(/public/cibanners/brick-bg.png);
    background-size: cover;
}

.home-background{
    background-image: url(/public/cibanners/home-bg.png);
    background-size: cover;
}